import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet";
import {Link} from "gatsby"
import {GatsbyImage, StaticImage} from "gatsby-plugin-image"
import { Section, SectionPageTitle, Nodata, NodataGrid, NodataGridLeft, NodataGridRight, NodataFigure, NodataContent } from "../components/Section"
import {CartIcon} from "../components/Icons"
import CallNow from "../components/CallNow"
import BreakpointUp from "../components/Media/BreakpointUp"
import BlackButton from "../components/Button/BlackButton"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton"
import PrimaryButton from "../components/Button/PrimaryButton"
import { Context } from "../components/context"


const CardPrimary = styled.div`
    background-color:#0B619B;   
    color:#fff;
    box-shadow: 0 20px 50px rgba(0,0,0,0.3);
    margin-bottom:30px;
`
const CardPrimaryTitle = styled.h2`    
    font-size: 20px;
    line-height: 30px;
    color:#fff;
    @media (min-width: 768px) {
        font-size: 24px;
        line-height: 34px;
    }
    @media (min-width: 992px) {
        font-size: 28px;
        line-height: 38px;
    }
`
const CardPrimaryBody = styled.div`
    padding:30px 15px;
    ${BreakpointUp.sm`	
        padding:30px;
    `} 
    ${BreakpointUp.lg`	
        padding:60px 40px;
    `} 
`
const PriceTable = styled.div`
    margin-bottom:60px;
`
const PriceRow = styled.div`
    display:flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`
const PriceCol = styled.div`
    padding:15px 10px;
`
const PriceLabel = styled.div`
    color: #fff;
    min-width: 100px;
`
const SubTotal = styled.div`
   font-weight:700;
`
const Total = styled.div`
   font-weight:700;
    font-size: 18px;
    line-height: 28px;
    @media (min-width: 768px) {
        font-size: 20px;
        line-height: 30px;
    }
    @media (min-width: 992px) {   
        font-size: 24px;
        line-height: 34px;
    }
`
const PriceAction = styled.div`
    pointer-events: ${props => props.term ? "unset": "none"};
    opacity: ${props => props.term ? "1": "0.5"};

    a{
        display:block;
        width:100%;
    }
    .btn{
        width: 100%;
    }
`
const CartPanel = styled.div`
    display: flex;
	flex-wrap: wrap;
	margin:0 -15px;
`
const CartLeftPanel = styled.div`
    position:relative;
	width:100%;
	padding:0 15px;
	@media(min-width: 992px){
		flex: 0 0 66.666667%;
        max-width: 66.666667%;
	}
`
const CartRightPanel = styled.div`
    position:relative;
	width:100%;
	padding:0 15px;
	@media(min-width: 992px){
		flex: 0 0 33.333333%;
        max-width: 33.333333%;
	}
`


const CartTable = styled.div`
    margin-bottom:30px;
    font-weight:600;
    @media (min-width: 768px) {
        font-size: 18px;
        line-height: 28px;
    }
`
const CartRow = styled.div`
    display:flex;
    align-items: center;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbdb;
    @media (max-width: 991px) {
        flex-wrap: wrap;
    }
`
const CartCol = styled.div`
    padding:20px 10px;
    &:first-child{        
        max-width:220px;
        width:100%;
        @media(max-width: 991px) {
            width: 50%;
            max-width: 50%;
        }
    }
    @media (max-width: 575.98px) {
        flex: 0 0 50%;
        max-width: 50%;
        &:last-child{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    &:last-child{
        text-align: right;
        width: 150px;
    }
    .form-control{
        width: 40px;
        text-align: center;
    }
    .btn{
        font-weight:400;
        text-align:right;
        padding:0 !important;
        text-decoration:underline;
    }
    &.name {
        width: 35%;
        max-width: calc(100% - 600px);
        @media(max-width: 991px) {
            width: 50%;
            max-width: 50%;
        }
    }
    &.price {
        width: 100px;
    }
    &.amount {
        display: flex;
        width: 150px;
        align-items: center;
        justify-content: space-between;

        button {
            padding: 0 10px;
        }
    }
`
const CartProduct = styled.div`
    max-width:292px;
    width:100%;    
`
const CartAction = styled.div`
    display:flex;
    justify-content: flex-end;
   margin:0  -10px 30px  -10px;
   button,a{
    padding:0 10px;
   }
`
const TermsContent = styled.div`
    position:relative;
    background-color: #f7f7f7;
    padding:20px;
    overflow-y:auto;
    overflow-x:hidden;
    max-height:260px;
    transition: all 0.5s;
    border-radius: 5px;
    >p, >ul{
        font-size:14px;
        line-height:24px;
        color:#000;
    }
    /* Scrollbar Styling */
    &::-webkit-scrollbar {
        width: 10px;
    }
    
    &::-webkit-scrollbar-track {
        background-color: #ebebeb;
    }

    &::-webkit-scrollbar-thumb {
        background: #6d6d6d; 
    }
`

const TermCloseBtn = styled.button`
    position: absolute;
    width: 20px;
    height: 20px;
    float: right;
    top: -30px;
    right: 0px;
    font-size: 20px;
`

const Terms = styled.div`
    position: relative;
    .form-check{
        margin-top:30px;

        button {
            color: #45BAE7;
        }
    }
    &.show{
    } 
`

const CartList = () => {
    const [showTerms, setShowTerms] = useState(false);
    const [subTotal, setSubTotal] = useState();
    const [total, setTotal] = useState();
    const [termCheck, setTermCheck] = useState(false);
    const [cartData, setCartData] = useContext(Context);
    const ToggleTerms = () => {
        setShowTerms(!showTerms);
    }
    const increase = (product) => {
        const increaseCartData = cartData.map((cartItem) =>
            cartItem.id === product.id
            ? { ...cartItem, count: cartItem.count + 1 }
            : cartItem
        );
        setCartData(increaseCartData);
        console.log("increase =", cartData)
    };
    const decrease = (product) => {
        const decreaseCartData = cartData.map((cartItem) =>
            cartItem.id === product.id
            ? { ...cartItem, count: cartItem.count > 1 ? cartItem.count - 1 : 1 }
            : cartItem
        );
        setCartData(decreaseCartData);
    };
    
    const removeItem = (id) => {
        const removeItemCartData = cartData.filter((cartItem) => cartItem.id !== id);
        setCartData(removeItemCartData);
    };

    useEffect(() => {
        let subTotalPrice = 0
        cartData?.map((cartItem) => {
            subTotalPrice = subTotalPrice + cartItem.price * cartItem.count
        })
        setSubTotal(subTotalPrice);
        setTotal(subTotalPrice);
    }, [cartData, setCartData])
    return (
        <CartPanel>
            <CartLeftPanel>
                <SectionPageTitle textAlign="left">Cart</SectionPageTitle>
                { cartData?.length > 0 ? (
                    <>
                        
                        <CartTable>
                        {
                            cartData.map((item) => {
                                const cartPrice = (item.price * item.count).toLocaleString("en-US");
                                return(
                                    <CartRow key={item.id}>
                                        <CartCol>
                                            <CartProduct>
                                                <GatsbyImage image={item.productImages.gatsbyImageData} alt="carport" />
                                            </CartProduct>
                                        </CartCol>
                                        <CartCol className="name">{item.productName}</CartCol>
                                        <CartCol className="price">${parseInt(item.price).toLocaleString("en-US")}</CartCol>
                                        <CartCol className="amount">
                                            <button onClick={()=> decrease(item)}>-</button>
                                            <input type="text" value={item.count} id="quantity" className="form-control" name="qty"  aria-label="Product quantity" size="4" min="0" max="" disabled />
                                            <button onClick={()=> increase(item)}>+</button>
                                        </CartCol>
                                        <CartCol className="total-price">
                                            <Total>${cartPrice}</Total>
                                            <button type="button" className="btn-sm" aria-label="Remove" onClick={()=>removeItem(item.id)}><PrimaryLinkButton  text="Remove" /></button>
                                        </CartCol>
                                    </CartRow>
                                )}
                            )
                        }
                            
                        </CartTable>
                        <CartAction>
                            <Link to="/products" className="btn-sm"><PrimaryLinkButton text="Continue shopping" /></Link>
                        </CartAction>
                    </>
                ):(
                    <>
                        <Nodata>
                            <NodataGrid>
                                <NodataGridLeft>
                                    <NodataFigure className="text-center"><StaticImage src="../images/empty-cart.png" alt="empty cart" /></NodataFigure>
                                </NodataGridLeft>
                                <NodataGridRight>
                                    <NodataContent className="text-center">
                                        <p>There is no product in the cart</p>
                                        <Link to="/products" className="btn-sm"><PrimaryButton text="Continue shopping" /></Link>
                                    </NodataContent>
                                </NodataGridRight>
                            </NodataGrid>
                        </Nodata>
                    </>
                )}
            </CartLeftPanel>
            <CartRightPanel>
            <div className="isSticky">
                <CardPrimary>
                    <CardPrimaryBody>
                        <CardPrimaryTitle>CART TOTALS</CardPrimaryTitle>
                        <PriceTable>
                            <PriceRow>
                                <PriceCol>
                                    <PriceLabel>SUBTOTAL</PriceLabel>
                                </PriceCol>
                                <PriceCol>
                                    <SubTotal>${parseInt(subTotal).toLocaleString("en-US")}</SubTotal>
                                </PriceCol>
                            </PriceRow>
                            <PriceRow>
                                <PriceCol>
                                    <PriceLabel>TOTAL</PriceLabel>
                                </PriceCol>
                                <PriceCol>
                                    <Total>${parseInt(total).toLocaleString("en-US")}</Total>
                                </PriceCol>
                            </PriceRow>
                        </PriceTable>
                            { cartData?.length > 0 && (
                                <Terms className={showTerms ? '' : 'show'}>
                                    { showTerms && 
                                        <>
                                            <TermCloseBtn onClick={()=>setShowTerms(false)}>x</TermCloseBtn>
                                            <TermsContent className="terms-content">
                                                
                                                <h2 className="h5">Coast to Coast - Pricing Disclaimer</h2>
                                                <p>At Coast to Coast Carports, we offer an array of metal building options, including carports, garages, barns, sheds, and more. Our sales team is here to serve you with the most competitive pricing and highest quality of service. Before you commit to any purchase from us, please read this pricing disclaimer so that you are comfortable with the cost structure for our products and services.</p>

                                                <p>In order to provide you with the most reliable pricing estimate, we consider a variety of factors. Steel market prices, geographical location, building type and size, customizations, roof style, building certification, foundation, and the use of metal buildings, are all taken into account when calculating our price quotes. Our team of experienced professionals will take the time to understand your requirements, limitations, and budget, helping you understand the scope, implications, and trade-offs of your different options before providing a competitive quote.</p>
                                                
                                                <p>We would like to mention that a price quote is the base price and that there may be other costs involved in the project that must be taken into account before you receive an accurate estimate of the total project cost. Moreover, the quoted price is not guaranteed to remain the same, as it may change depending on the current market conditions and other factors.</p>

                                                <p>If you have any concerns or questions regarding our pricing, please feel free to give us a call at <a href="tel:8666817846">(866) 681-7846</a>. Our experienced staff are always ready to help you with your questions, and to make sure you are getting the best prices available. Thank you for choosing Coast to Coast Carports for your metal building project!</p>
                                            </TermsContent>
                                        </>
                                    }
                                    <div className="form-check">
                                        <span className="form-check-text">I understand that the price may vary.<button type="button" onClick={ToggleTerms}>Read Pricing Disclaimer</button></span>                                
                                        <input className="form-check-input" type="checkbox" value="" id="terms" onChange={(e) => setTermCheck(e.target.checked)}  />
                                        <label className="form-check-label" htmlFor="terms"></label>
                                    </div>
                                </Terms>
                            )}
                        <PriceAction term={termCheck}><Link to="/checkout"><BlackButton text="Proceed to Checkout" icon={<CartIcon />} /></Link></PriceAction>
                    </CardPrimaryBody>
                </CardPrimary>                
            </div>
            </CartRightPanel>
        </CartPanel>
    )
}

const CartPage = ({ location }) => {
    return (
        <Layout location={location}>
            <Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
            <Seo title="cart" description="cart" />
            <Section
                pt="215px"
                pb="90px"
                xpt="120px"
                xpb="60px"
                bgColor="#fff"
            >
                <div className="container">
                    <CartList />
                </div>
            </Section>
            <CallNow />
        </Layout>
    )
}
export default CartPage